<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Choices from "choices.js";

import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'
import moment from 'moment'
import { financial } from '@/api/misc'

/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM Termination",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {
    if (this.release_type == 'Owner' || this.release_type == 'Tenant') {
      return {
        selected_amount: { required },
        supplier_address: {},
        supplier_name: {},
        invoice_number: { },
      }
    } else {
      return {
        selected_amount: { required },
        supplier_address: {required},
        supplier_name: {required},
        invoice_number: { required },
      }
    }

  },


  methods: {

    f(v, d =2) {
      return financial(v, d)
    },

    formSubmit() {

      if (this.has_data_error) {
        return
      }
      let data = {
        pm_id : this.property.property_id,
      }

      console.log(this.listingData)
      let release_owner = this.listingData.find(e =>e.pay_type == 'Owner')
      if (release_owner) {
        data.owner_amount = release_owner.amount
      }

      let release_tenant = this.listingData.find(e =>e.pay_type == 'Tenant')
      if (release_tenant) {
        data.tenant_amount = release_tenant.amount
      }

      data.expense_list =  this.listingData.filter(e =>e.pay_type == 'Supplier')


      getPMApi().terminate_pm(data).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Property Terminate  successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error(" Property Terminate  failed:" + res.errCode);
        }
      })


    },


    removePMEntry(data) {
      this.listingData.splice(data.index, 1)
      this.check_amount()
    },


    onSearchSupplier(str) {

      autoComplete({ types: ['SEARCH_SUPPLIER'], str: str, selection: ['property_id', 'o_code', 'address'] }).then(res => {
        if (res.errCode == 0) {
          this.searched_supplier_data = []
          res.data.map(e => {
            this.searched_supplier_data.push({
              str_id: e.name + '(' + e.supplier_code + ')',
              supplier: e
            })
          })
        }

      })
    },

    onSupplierSelected(evt) {
      this.supplier_name = evt.supplier.name
      this.supplier_address = evt.supplier.address
      this.supplier_code = evt.supplier.supplier_code
    },


    check_amount() {
      this.has_data_error = false
      let amount = this.listingData.reduce((a, c) => a+c.amount, 0)

      if (amount > Number(this.property.deposit) + Number(this.property.owner_reserved)) {
        this.has_data_error  = true
      }
    },

    addReleaseDetail() {
      this.$v.$touch()
      if (this.$v.$invalid == true) {
        return
      }
      if (this.release_type == 'Owner' || this.release_type == 'Tenant') {

       
        let item = this.listingData.find(e => e.pay_type == this.release_type)
        if (item) {
          item.amount = Number(this.selected_amount)
        } else {
          this.listingData.push({
            pay_type : this.release_type,
            amount   : Number(this.selected_amount),
            payee   : this.release_type == 'Owner' ? this.property.owner_name : this.property.t_name
          })
        }
        
      } else  {
        this.listingData.push({
            pay_type : this.release_type,
            amount   : Number(this.selected_amount),
            payee   : this.supplier_name,
            address : this.supplier_address,
            invoice : this.invoice_number
          })
      }

      this.check_amount()
    },



  },

  data() {
    return {
      title: "PM Termination",
      items: [
        {
          text: "PM List",
          href: "/pm/property_list",
        },
        {
          text: "PM Termination",
          active: true,
        },
      ],


      has_data_error: false,

      property : {},
      supplier_code: '',
      supplier_name: '',
      supplier_address: '',
      release_type : 'Owner',
      invoice_number: '',
      selected_amount : '',

      searched_supplier_data: [],
      listingData: [],
     
      total_amount: 0
    };
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead,

  },
  created() {
    let m = moment()
    console.log(m)
    getPMApi().detail({ pm_id: this.$route.query.pm_id }).then((res) => {
     if (res.errCode == 0) {
       this.property = res.data
     }

   })
  },
  mounted() {
    new Choices('#release_to_type', { itemSelectText: '' })
  },

  watch: {

    supplier_name(new_supplier) {
      this.onSearchSupplier(new_supplier)
    },
  }
};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <h5 class="card-title mb-4">Basic Info --- {{ property.pm_id }}  <label>Deposit: ${{ f(property.deposit).toLocaleString() }}</label> <label>Owner Reserve: ${{ f(property.owner_reserved).toLocaleString() }}</label></h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-3">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Balance Release     </h5>
                  </div>
                </div>
                <div class="card-body">

                  <div class="row">
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Release To</label>
                        <select id="release_to_type" class="form-control" v-model="release_type">
                          <option value="Tenant">Tenant</option>
                          <option value="Supplier">Supplier</option>
                          <option value="Owner">Owner</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Amount</label>
                        <div class="mb-3">
                          <input class="form-control" v-model="selected_amount"
                            :class="{ 'is-invalid': $v.selected_amount.$error }" />
                          <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                            <span v-if="!$v.selected_amount.required">This value is required.</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-6">
                    </div>
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <b-button variant="primary" @click="addReleaseDetail">Add to Detail</b-button>
                      </div>
                    </div>

                  </div>



                  <div class="row mt-3 mb-3">
                    <div class="col-lg-3">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Supplier</label>
                        <vue-bootstrap-typeahead ref="supplier_name_ref" :disabled="release_type !='Supplier'"
                          v-model="supplier_name" :value="supplier_name" :data="searched_supplier_data" 
                          :inputClass="{ 'is-invalid': $v.supplier_name.$error }" :serializer="s => s.str_id"
                          :foramterDisplay="s => s.str_id" @hit="onSupplierSelected($event)" autocomplete="off" />
                      </div>
                    </div>

                    <div class="mb-3 col-lg-6">
                      <label class="form-label" for="gen-info-name-input">Supplier Address</label>
                      <input class="form-control" :disabled="release_type !='Supplier'" v-model="supplier_address"
                        :class="{ 'is-invalid': $v.supplier_address.$error }" />
                      <div v-if="$v.supplier_address.$error" class="invalid-feedback">
                        <span v-if="!$v.supplier_address.required">This value is required.</span>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Invoice Number</label>
                        <input class="form-control" v-model="invoice_number" :disabled="release_type !='Supplier'"
                          :class="{ 'is-invalid': $v.invoice_number.$error }" />
                        <div v-if="$v.invoice_number.$error" class="invalid-feedback">
                          <span v-if="!$v.invoice_number.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>


              </div>
              <!-- end card -->

              <div class="row">
                <b-alert show variant="danger" v-if="has_data_error">Released Amount Exceed Balance</b-alert>
              </div>


              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Balance Release Detail</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div data-simplebar>
                    <div class="table-responsive mt-3">
                      <div class="table align-middle table-nowrap">
                        <b-table :items="listingData"
                          :fields="['ReleaseTo', 'Amount', 'Invoice',  'Remove']"
                          responsive="sm" :per-page="listingData.length" class="table-check" >
                         
                          <template #cell(ReleaseTo)="data">
                            {{ data.item.payee }}
                          </template>
                          <template #cell(Amount)="data">
                            ${{ data.item.amount.toLocaleString() }}
                          </template>

                          <template #cell(Invoice)="data">
                            {{ data.item.invoice }}
                          </template>
                          <template #cell(Remove)="data">
                            <div class="row icon-demo-content-10">
                              <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMEntry(data)"><i
                                  class="uil-times font-size-4"></i></div>
                            </div>
                          </template>



                        </b-table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->



              <div class="text-end mb-5">
                <button type="submit" class="btn btn-danger w-sm">
                  Submit
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

